import React from "react";
import { StaticImage } from "gatsby-plugin-image";

import LessonLayout from "~/layouts/lesson";

import ContentWrapper from "~/components/content-wrapper";
import H2 from "~/components/Typography/H2";

const Page = () => {
  return (
      <LessonLayout
        current_step={4}
        lesson={"Lesson 2"}
        color={"hs"}
        total_count={10}
        module_title="Separating Fact from Fiction"
      >
        <ContentWrapper>
          <H2 underline>Step 4</H2>

          <p>The green coffee bean extract website mentioned a "clinical trial." A <strong>clinical trial</strong> is a type of scientific experiment that involves human subjects.</p>


          <div className="mt-5 w-2/3 flex content-end">
            <StaticImage
              alt="Hand using tablet."
              className="rounded"
              src="../../../images/lesson_2_step_4.jpg"
              loading="eager"
              placeholder="blurred"
              style={{
                filter: "drop-shadow(8px 8px 8px rgba(0, 0, 0, 0.25))"
              }}
            />
          </div>

          <p>Think about experiments you have done in your science classes. As you carried out these experiments, you have learned some important things about how experiments should be done.</p>
          <p>Work with a partner to think about what is important when designing a good experiment. For example, your list may start with having a testable question. Record your ideas in your science notebook.</p>
          <p>Be ready to share your ideas in a class discussion.</p>
        </ContentWrapper>
      </LessonLayout>
  )
}

export default Page;
